import styled from 'styled-components';

export const Container = styled.ul`
  list-style-type: none;
  margin: 10px;
`;

export const Log = styled.li`
  margin: 0 10px;
  width: 100%;
  padding-bottom: 3px;

  strong {
    color: #555;
    font-size: 14px;
    margin-top: 5px;
    padding-left: 10px;
  }

  small {
    color: #666;
    font-size: 12px;
  }
`;
