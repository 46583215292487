import styled from 'styled-components';

export const Container = styled.div`
  /* display: flex;
  flex-direction: row; */
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow: auto;
  /* justify-content: center; */
  margin-top: 50px;
`;

export const Track = styled.div`
  width: 400px;
  min-width: 400px;
  background: #fff;
  border-radius: 3px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  header {
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #555;

    strong {
      width: 100%;
      font-size: 16px;
      margin-top: 5px;
      padding-bottom: 15px;
      color: #555;
      text-align: center;

      button {
        width: 32px;
        height: 32px;
        background: #fff;
        color: #555;
        border: 0;
        font-size: 12pt;
      }
    }

    small {
      font-size: 14px;
      color: #666;
      width: 100%;
      align-items: left;
      padding-bottom: 5px;

      i {
        padding-right: 5px;
      }
    }

    .label {
      width: 100%;
      font-size: 9pt;
      font-weight: bold;
    }

    img {
      width: 64px;
    }

    hr {
      color: #777;
      margin-top: 15px;
      width: 98%;
    }
  }
`;
