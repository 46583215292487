import React, { Component } from 'react';
import {
  Container, ApplicationName, Form, Error,
} from './styles';
import TrackList from '../../components/TrackList';
import logo from '../../assets/logo.png';
import api from '../../services/api';

export default class Main extends Component {
  state = {
    loading: false,
    trackerError: false,
    trackerErrorMessage: '',
    trackerInput: '',
    trackers: [],
  };

  async componentWillMount() {
    this.setState({ loading: true });
    this.setState({ loading: false, trackers: await this.getLocalTrackers() });
  }

  getLocalTrackers = async () => JSON.parse(await localStorage.getItem('@DataCTracker:trackers')) || [];

  handleAddTracker = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const { trackers, trackerInput } = this.state;
    try {
      const { data: tracker } = await api.get(`/tracker/${trackerInput}`);

      const isDuplicated = false;
      //   if (tracker.size !== 0) {
      //     isDuplicated = await trackers.find(t => t.id === tracker.id);
      //   }

      if (!isDuplicated) {
        this.setState({
          trackerInput: '',
          trackers: [...trackers, ...tracker],
          trackerError: false,
          trackerErrorMessage: '',
        });

        const localTrakers = await this.getLocalTrackers();

        await localStorage.setItem(
          '@DataCTracker:trackers',
          JSON.stringify([...localTrakers, ...tracker]),
        );
      } else {
        this.setState({ trackerError: true, trackerErrorMessage: 'Este código já foi utilizado' });
      }
    } catch (err) {
      this.setState({
        trackerError: true,
        trackerErrorMessage: 'Não foi possível encontrar o código',
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleRemoveTracker = async (id) => {
    const { trackers } = this.state;

    const updatedTrackers = trackers.filter(tracker => tracker.id !== id);
    this.setState({ trackers: updatedTrackers });
    await localStorage.setItem('@DataCTracker:trackers', JSON.stringify(updatedTrackers));
  };

  render() {
    const {
      trackers, trackerInput, trackerError, trackerErrorMessage, loading,
    } = this.state;
    return (
      <>
        <Container>
          <img src={logo} alt="Data C - Tracker" />
          <ApplicationName>TRACKER</ApplicationName>
          <Form withError={trackerError} onSubmit={this.handleAddTracker}>
            <input
              type="text"
              placeholder="Nº Rastreio/Chave Acesso"
              value={trackerInput}
              onChange={e => this.setState({ trackerInput: e.target.value })}
            />
            <button type="submit">
              {loading ? <i className="fa fa-spinner fa-pulse" /> : <i className="fa fa-search" />}
            </button>
          </Form>
          <Error>{!!trackerErrorMessage && trackerErrorMessage}</Error>
        </Container>
        <TrackList trackers={trackers} removeTracker={this.handleRemoveTracker} />
      </>
    );
  }
}
