import React from 'react';
import PropTypes from 'prop-types';
import { Container, Log } from './styles';

const LogList = ({ logs }) => (
  <Container>
    {logs.map(log => (
      <Log key={log.id}>
        <small>{log.dataHora}</small>
        <strong>{log.situacao.replace('CTRC', '')}</strong>
      </Log>
    ))}
  </Container>
);

LogList.propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      situacao: PropTypes.string,
    }),
  ).isRequired,
};

export default LogList;
