import React from 'react';
import PropTypes from 'prop-types';
import { Container, Track } from './styles';
import LogList from '../LogList';

const TracKList = ({ trackers, removeTracker }) => (
  <Container>
    {trackers.map(tracker => (
      <Track key={tracker.id}>
        <header>
          <strong>
            {tracker.numero_rastreio}
            <button type="button" onClick={() => removeTracker(tracker.id)}>
              <i className="fa fa-trash" />
            </button>
          </strong>
          <span className="label">Remetente:</span>
          <small title="Remetente">{tracker.remetente.nome}</small>
          <span className="label">Destinatário:</span>
          <small title="Destinatário">{tracker.destinatario.nome}</small>
          <hr />
        </header>
        <LogList logs={tracker.logs} />
      </Track>
    ))}
  </Container>
);

TracKList.propTypes = {
  trackers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      numero: PropTypes.number,
    }),
  ).isRequired,
  removeTracker: PropTypes.func.isRequired,
};

export default TracKList;
